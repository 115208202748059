table.sbs-table {
    border: 1px solid white;
    border-radius: var(--sbs-border-radius);
    width: 100%;
    font-size: 1.2rem;
    border-spacing: 0;
    border-collapse: none;

    .table-colspan{
        color: var(--sbs-primary);
    }


    th {
        text-align: right;
        border-bottom: 1px solid var(--sbs-white-opacity);
        padding: 0.5rem 0.25rem;
        color: var(--sbs-primary);
    }

    tr {
        td {
            padding: 0.5rem 0.25rem;
        }

        &:not(:last-child) {
            td {
                border-bottom: 1px solid var(--sbs-white-opacity);
            }
        }
    }

    .options{
        display: flex;
        gap: 1rem;
    }

}