.login-page {
    img {
        width: 100%;
        max-width: 300px;
    }

    .error-msg {
        color: red;
    }
}

.meal-title {
    font-size: 2rem;
    display: block;
}