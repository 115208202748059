input.sbs-input,
textarea.sbs-input {
    border: 1px solid white;
    background-color: transparent;
    font-size: 1rem;
    border-radius: var(--sbs-border-radius);
    color: white;
    padding: 5px 10px;

    &.search {
        background-position: 0.5rem;
        background-size: 1rem;
        background-repeat: no-repeat;
        padding-left: 2rem;

        &:not(.load){
            background-image: url('../../imgs/search.png');
        }
        
        background-image: url('../../svgs/loader.svg');
    }
}

input[type=date].sbs-input::-webkit-calendar-picker-indicator {
    filter: invert(100%);
}

input[type=number] {
    -moz-appearance: textfield;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.sbs-input-placeholder {
    position: relative;
    width: max-content;
    margin-top: 0.8rem;

    .placeholder {
        position: absolute;
        right: 4px;
        bottom: 2rem;
        font-size: 0.8rem;
        pointer-events: none;
        opacity: 1;
    }

    input {
        border: 1px solid white;
        background-color: transparent;
        font-size: 1rem;
        border-radius: var(--sbs-border-radius);
        color: white;
        padding: 5px 10px;

        &:placeholder-shown+.placeholder {
            /* if real placeholder is shown - hide fake placeholder */
            opacity: 0;
        }
    }

}