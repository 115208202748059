.verify-code-container {

    display: flex;
    gap: 0.5rem;

    input {
        max-width: 50px;
        text-align: center;
        border: 1px solid white;
        background-color: transparent;
        font-size: 1rem;
        border-radius: var(--sbs-border-radius);
        color: white;
        padding: 5px 10px;
        aspect-ratio: 1/1;

    }


}