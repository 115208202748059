.log-book-page {
    .page-header {
        svg {
            font-size: 2rem;
        }

        margin:0 1rem;
        position: relative;
        padding-bottom: 1rem;

        &::before {
            content: "";
            position: absolute;
            width: 80%;
            border-bottom: 1px solid white;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

        }

    }

    .repeat,
    .weight {
        input {
            width: 100%;
        }
    }

    .exercise-data {
        padding-top: 1rem;
        border-top: 1px solid white;

        .remove {
            background-color: #b61827;
            cursor: pointer;
            border: none;

            &.disabled{
                opacity: .5;
                pointer-events: none;
            }

        }
    }

    .hidden-timepicker {
        position: relative;

        input {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            // display: none;
        }
    }
}