.sbs-stepper{
    display: flex;
    justify-content: space-between;
    height: 1px;
    background-color: var(--sbs-white-opacity);
    align-items: center;
    margin: 25px 0;
    
    div{
        width: 50px;
        height: 50px;
        background-color: var(--sbs-primary);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        border: 5px solid var(--sbs-background);
        
        &:hover{
            background-color: var(--sbs-primary-variant);
            
        }
        
        &.curr{
            color: var(--sbs-primary-variant);
            background-image: var(--sbs-icon-gardient);
        }
    }
}