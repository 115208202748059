.lobby-page {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .logo{
        height: 300px;
        object-fit: contain;
    }

    .buttons-container {

        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-around;
        
        a {
            display: block;
            border-radius: var(--sbs-border-radius);
            background-color: var(--sbs-primary-variant);
            padding: 1rem;
            display: flex;
            flex-direction: column;
            font-size: 1.5;
            color: white;
            text-align: center;
            text-decoration: none;
            font-weight: 600;
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: var(--sbs-primary);
            }

            svg {
                font-size: 5rem;

            }
        }
    }
}